var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": "moveOrException-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.moveOrExceptionShow = false
            },
            hide: _vm.handleHide,
            close: _vm.abortCalculation,
            ok: _vm.moveOrExceptionOk,
            cancel: _vm.abortCalculation,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(" " + _vm._s(_vm.nonWorkModalTitle) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.showApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAllForMoveOrException,
                            callback: function ($$v) {
                              _vm.applyAllForMoveOrException = $$v
                            },
                            expression: "applyAllForMoveOrException",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.moveOrExceptionShow,
            callback: function ($$v) {
              _vm.moveOrExceptionShow = $$v
            },
            expression: "moveOrExceptionShow",
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.moveOrExceptionTaskMovedStatement))]),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("task_date_duration_calc.label_possible_actions"),
              },
            },
            [
              _c(
                "b-form-radio",
                {
                  attrs: {
                    name: "some-radios",
                    value: _vm.moveOrExceptionOptions.MOVE,
                  },
                  model: {
                    value: _vm.moveOrException.choice,
                    callback: function ($$v) {
                      _vm.$set(_vm.moveOrException, "choice", $$v)
                    },
                    expression: "moveOrException.choice",
                  },
                },
                [_vm._v(_vm._s(_vm.suggestMoveToDay))]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: {
                    name: "some-radios",
                    value: _vm.moveOrExceptionOptions.CALENDAR_EXCEPTION,
                    disabled: _vm.isNullCalendarProvided,
                  },
                  model: {
                    value: _vm.moveOrException.choice,
                    callback: function ($$v) {
                      _vm.$set(_vm.moveOrException, "choice", $$v)
                    },
                    expression: "moveOrException.choice",
                  },
                },
                [_vm._v(_vm._s(_vm.suggestCreateException))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": "beyondProjectDate-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.beyondProjectDateShow = false
            },
            hide: _vm.handleHide,
            close: _vm.abortCalculation,
            ok: _vm.beyondProjectDateOk,
            cancel: _vm.abortCalculation,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "task_date_duration_calc.beyond_project_date.title"
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.showApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAllForBeyondProjectDate,
                            callback: function ($$v) {
                              _vm.applyAllForBeyondProjectDate = $$v
                            },
                            expression: "applyAllForBeyondProjectDate",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.beyondProjectDateShow,
            callback: function ($$v) {
              _vm.beyondProjectDateShow = $$v
            },
            expression: "beyondProjectDateShow",
          },
        },
        [
          _vm._l(
            _vm.beyondProjectDateTaskMovedStatements,
            function (statement, index) {
              return [_c("p", { key: index }, [_vm._v(_vm._s(statement))])]
            }
          ),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": "constraintChange-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.constraintChangeShow = false
            },
            hide: _vm.handleHide,
            close: _vm.abortCalculation,
            ok: _vm.constraintChangeOk,
            cancel: _vm.abortCalculation,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "task_date_duration_calc.constraint_change.title"
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.showApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAllForConstraintChange,
                            callback: function ($$v) {
                              _vm.applyAllForConstraintChange = $$v
                            },
                            expression: "applyAllForConstraintChange",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.constraintChangeShow,
            callback: function ($$v) {
              _vm.constraintChangeShow = $$v
            },
            expression: "constraintChangeShow",
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.constraintChangeStatements))]),
          _c(
            "b-form-group",
            [
              _c(
                "b-form-radio",
                {
                  attrs: {
                    name: "some-radios",
                    value: _vm.constraintChangeOptions.KEEP,
                  },
                  model: {
                    value: _vm.constraintChange.choice,
                    callback: function ($$v) {
                      _vm.$set(_vm.constraintChange, "choice", $$v)
                    },
                    expression: "constraintChange.choice",
                  },
                },
                [_vm._v(_vm._s(_vm.suggestKeepConstraint))]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: {
                    name: "some-radios",
                    value: _vm.constraintChangeOptions.CHANGE,
                  },
                  model: {
                    value: _vm.constraintChange.choice,
                    callback: function ($$v) {
                      _vm.$set(_vm.constraintChange, "choice", $$v)
                    },
                    expression: "constraintChange.choice",
                  },
                },
                [_vm._v(_vm._s(_vm.suggestChangeConstraint))]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: {
                    name: "some-radios",
                    value: _vm.constraintChangeOptions.MANUAL_SCHEDULE,
                  },
                  model: {
                    value: _vm.constraintChange.choice,
                    callback: function ($$v) {
                      _vm.$set(_vm.constraintChange, "choice", $$v)
                    },
                    expression: "constraintChange.choice",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "task_date_duration_calc.constraint_change.choice_manual_schedule"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": "manual-schedule-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.manualScheduleChangeShow = false
            },
            close: _vm.abortCalculation,
            ok: _vm.manualScheduleChangeOk,
            cancel: _vm.abortCalculation,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("title_schedule_mode")) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.showApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAllForManualChange,
                            callback: function ($$v) {
                              _vm.applyAllForManualChange = $$v
                            },
                            expression: "applyAllForManualChange",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.manualScheduleChangeShow,
            callback: function ($$v) {
              _vm.manualScheduleChangeShow = $$v
            },
            expression: "manualScheduleChangeShow",
          },
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("statement_suggest_manual_schedule_for_task"))
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }