<template>
  <div>
    <!-- NON-WORK-DAY -->
    <b-modal
        v-model="moveOrExceptionShow"
        @hidden="moveOrExceptionShow=false"
        @hide="handleHide"
        @close="abortCalculation"
        @ok="moveOrExceptionOk"
        @cancel="abortCalculation"
        content-class="moveOrException-modal shadow"
        no-close-on-backdrop
        >
      <template v-slot:modal-title>
        <div>
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
          {{ nonWorkModalTitle }}
        </div>
      </template>
      <p>{{ moveOrExceptionTaskMovedStatement }}</p>
      <b-form-group :label="$t('task_date_duration_calc.label_possible_actions')">
        <b-form-radio v-model="moveOrException.choice" name="some-radios" :value="moveOrExceptionOptions.MOVE">{{ suggestMoveToDay }}</b-form-radio>
        <b-form-radio v-model="moveOrException.choice" name="some-radios" :value="moveOrExceptionOptions.CALENDAR_EXCEPTION" :disabled="isNullCalendarProvided">{{ suggestCreateException }}</b-form-radio>
      </b-form-group>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="showApplyAllCheckbox" class="apply-to-all" v-model="applyAllForMoveOrException">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- OUT-OF-PROJECT-DATE-RANGE -->
    <b-modal
        v-model="beyondProjectDateShow"
        @hidden="beyondProjectDateShow=false"
        @hide="handleHide"
        @close="abortCalculation"
        @ok="beyondProjectDateOk"
        @cancel="abortCalculation"
        content-class="beyondProjectDate-modal shadow"
        no-close-on-backdrop  
        >
      <template v-slot:modal-title>
        <div>
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
          {{ $t('task_date_duration_calc.beyond_project_date.title') }}
        </div>
      </template>
      <template v-for="(statement, index) in beyondProjectDateTaskMovedStatements">
        <p :key="index">{{ statement }}</p>
      </template>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="showApplyAllCheckbox" class="apply-to-all" v-model="applyAllForBeyondProjectDate">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- CONSTRAINT-CHANGE-DAY -->
    <b-modal
        v-model="constraintChangeShow"
        @hidden="constraintChangeShow=false"
        @hide="handleHide"
        @close="abortCalculation"
        @ok="constraintChangeOk"
        @cancel="abortCalculation"
        content-class="constraintChange-modal shadow"
        no-close-on-backdrop  
        >
      <template v-slot:modal-title>
        <div>
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
          {{ $t('task_date_duration_calc.constraint_change.title') }}
        </div>
      </template>
      <p>{{ constraintChangeStatements }}</p>
      <b-form-group>
        <b-form-radio v-model="constraintChange.choice" name="some-radios" :value="constraintChangeOptions.KEEP">{{ suggestKeepConstraint }}</b-form-radio>
        <b-form-radio v-model="constraintChange.choice" name="some-radios" :value="constraintChangeOptions.CHANGE">{{ suggestChangeConstraint }}</b-form-radio>
        <b-form-radio v-model="constraintChange.choice" name="some-radios" :value="constraintChangeOptions.MANUAL_SCHEDULE">{{ $t('task_date_duration_calc.constraint_change.choice_manual_schedule') }}</b-form-radio>
      </b-form-group>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="showApplyAllCheckbox" class="apply-to-all" v-model="applyAllForConstraintChange">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- MANUAL-SCHEDULE-CHANGE -->
    <b-modal
        v-model="manualScheduleChangeShow"
        @hidden="manualScheduleChangeShow=false"
        @close="abortCalculation"
        @ok="manualScheduleChangeOk"
        @cancel="abortCalculation"
        content-class="manual-schedule-modal shadow"
        no-close-on-backdrop  
        >
      <template v-slot:modal-title>
        <div>
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
          {{ $t('title_schedule_mode') }}
        </div>
      </template>
      <p>{{ $t('statement_suggest_manual_schedule_for_task') }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="showApplyAllCheckbox" class="apply-to-all" v-model="applyAllForManualChange">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { calcDateTimeDurationv2, nextAvailableWorkingDay, getEarliestOrLatestWorkHour
 , TRIGGERS, MODAL_TYPES, isConstraintTypeStartDateRelated, isConstraintTypeCloseDateRelated } from '@/helpers/task-duration-process';

const MOVE_OR_EXCEPTION_ACTION_CHOICE = {
  MOVE: 'move'
  , CALENDAR_EXCEPTION: 'exception'
}
Object.freeze(MOVE_OR_EXCEPTION_ACTION_CHOICE);


const CONSTRAINT_CHANGE_ACTION_CHOICE = {
  KEEP: 'keep'
  , CHANGE: 'change'
  , MANUAL_SCHEDULE: 'manualSchedule'
}
Object.freeze(CONSTRAINT_CHANGE_ACTION_CHOICE);

const WORK_EXCEPTION_NAME = '[unnamed]';

const CALENDAR_EXCEPTION_TYPES = {
  LEAVE: 'Leave'
  , WORKING: 'Working'
}
Object.freeze(CALENDAR_EXCEPTION_TYPES);

export default {
  name: 'TaskDateTimeDurationCalculation',
  components: {
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    , taskName: {
      type: String
      , default: null
    }
    , calendar: {
      type: Object
      , default: null
    }
    , trigger: {
      type: String
      , default: null
    }
    , startDateStr: {
      type: String
      , default: null
    }
    , startTimeStr: {
      type: String
      , default: null
    }
    , closeDateStr: {
      type: String
      , default: null
    }
    , closeTimeStr: {
      type: String
      , default: null
    }
    , durationDisplay: {
      type: String
      , default: null
    }
    , constraintType: {
      type: String
      , default: null
    }
    , constraintDateStr: {
      type: String
      , default: null
    }
    , projectScheduleFromStart: {
      type: Boolean
      , default: true
    }
    , taskAutoScheduleMode: {
      type: Boolean
      , default: true
    }
    , projectStartDateStr: {
      type: String
      , default: null
    }
    , projectCloseDateStr: {
      type: String
      , default: null
    }
    , oldDateStr: {
      type: String
      , default: null
    }
    , oldTimeStr: {
      type: String
      , default: null
    }
    , lockDuration: {
      type: Boolean
      , default: false
    }
    , skipOutOfProjectDateCheck: {
      type: Boolean
      , default: false
    }
    , taskType: {
      type: String
      , default: null
    }
    , resizeMode: {
      type: Boolean
      , default: false
    }
    , defaultActionForNonWorkPrompt: {
      type: String
      , default: null //Possible value: null | move | calendar_exception
      //Note: null or invalid value will be ignored.
    }
    , defaultActionForConstraintChangePrompt: {
      type: String
      , default: null //Possible value: null | keep | change | manual_schedule
      //Note: null or invalid value will be ignored.
    }
    , clearPreviousChoice: {
      type: Boolean
      , default: false
    }
    , showApplyAllCheckbox: {
      type: Boolean
      , default: false
    }
    , oldConstraintType: {
      type: String
      , default: null
    }
    , oldConstraintDateStr: {
      type: String
      , default: null
    }
    , disableDurationBuffer: {
      type: Boolean
      , default: false
    }
    , enableManualScheduleSuggestion: {
      type: Boolean
      , default: false
    }
    , durationConversionOpts: {
      type: Object
      , default: () => {}
    }
  }
  , data() {
    return {
      moveOrExceptionShow: false
      , moveOrException: {
        targetDate: null //moment.utc('2021-06-05', 'YYYY-MM-DD')
        , suggestedDate: null //moment.utc('2021-06-07', 'YYYY-MM-DD')
        , isPrevious: false
        , choice: MOVE_OR_EXCEPTION_ACTION_CHOICE.MOVE
        // Keep a copy of datetime value of result object, it may contain the latest value adjusted in previous calculate() call.
        // e.g: When the suggestedDate (startDate) is later than closeDate and duration is not provided, calculate() adjusts closeDate to a new value to fit the suggestedDate.
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
      }
      , beyondProjectDateShow: false
      , beyondProjectDate: {
        targetDate: null
        , projectDate: null
        , isStart: false
        , isStartTrigger: false
      }
      , constraintChangeShow: false
      , constraintChange: {
        targetConstraintType: null //e.g. CONSTRAINT_TYPES.SNET
        , targetConstraintDate: null // moment.utc('2021-06-07, 'YYYY-MM-DD')
        , suggestedConstraintType: null //e.g. CONSTRAINT_TYPES.SNET
        , suggestedConstraintDate: null //e.g. moment.utc('2021-06-07, 'YYYY-MM-DD')
        , choice: CONSTRAINT_CHANGE_ACTION_CHOICE.KEEP
      }
      , manualScheduleChangeShow: false
      
      , applyAllForMoveOrException: false
      , applyAllForBeyondProjectDate: false
      , applyAllForConstraintChange: false
      , applyAllForManualChange: false
      , previousMoveOrExceptionChoice: null   // null | 'move' | 'calendar_exception'
      , previousBeyondProjectDateChoice: false // true | false
      , previousConstraintChangeChoice: null // null | 'keep' | 'change' | 'manual_schedule'
      , previousManualChangeChoice: false
      , localSkipOutOfProjectDateCheck: false // Only kept within a session. Will be reset in every dialog prompt.
      , isExpectedClose: true // Used to handle the flow of modal being closed on Esc key.
    }
  }
  , created() {
  }
  , watch: {
    show(newValue) {
      if (newValue) {
        this.isExpectedClose = false;
        this.localSkipOutOfProjectDateCheck = this.skipOutOfProjectDateCheck;
        if (this.taskAutoScheduleMode && this.enableManualScheduleSuggestion 
            && ((this.trigger == TRIGGERS.START_DATE && this.projectScheduleFromStart)
              || (this.trigger == TRIGGERS.CLOSE_DATE && !this.projectScheduleFromStart)) 
            && !this.previousManualChangeChoice) {
          this.manualScheduleChangeShow = true;
        } else {
          this.initCalculation();
        }
      }
    }
    , moveOrExceptionShow(newValue) {
      if (!newValue) {
        this.moveOrException.choice = MOVE_OR_EXCEPTION_ACTION_CHOICE.MOVE;
      }
    }
    , clearPreviousChoice(newValue) {
      if (newValue) {
        this.previousMoveOrExceptionChoice = null;
        this.previousBeyondProjectDateChoice = false;
        this.previousConstraintChangeChoice = null;
        this.previousManualChangeChoice = false;
        this.applyAllForMoveOrException = false;
        this.applyAllForBeyondProjectDate = false;
        this.applyAllForConstraintChange = false;
        this.applyAllForManualChange = false;
      }
      this.$nextTick(() => {
        this.$emit('update:clearPreviousChoice', false);
      });
    }
    , constraintChangeShow(newValue) {
      if (!newValue) {
        this.constraintChange.choice = CONSTRAINT_CHANGE_ACTION_CHOICE.KEEP;
      }
    }
  }
  , computed: {
    nonWorkModalTitle() {
      if (this.trigger === TRIGGERS.CLOSE_TIME) {
        return this.$t('task_date_duration_calc.non_work.finish_title');
      }
      else if (this.trigger === TRIGGERS.START_TIME) {
        return this.$t('task_date_duration_calc.non_work.start_title');
      }
      return this.$t('task_date_duration_calc.non_work.title');
    }
    , moveOrExceptionTaskMovedStatement() {
      if (this.taskName != null && this.moveOrException != null && this.moveOrException.targetDate != null) {
        if (this.moveOrException.isPrevious) {
          if (this.taskName) {
            return this.$t('task_date_duration_calc.non_work.content_line_finish', [this.taskName, this.moveOrException.targetDate.format('dddd, Do MMMM YYYY')]);
          }
          else {
            return this.$t('task_date_duration_calc.non_work.content_line_finish_no_name', [this.moveOrException.targetDate.format('dddd, Do MMMM YYYY')]);
          }
        }
        if (this.taskName) {
          return this.$t('task_date_duration_calc.non_work.content_line_start', [this.taskName, this.moveOrException.targetDate.format('dddd, Do MMMM YYYY')]);
        }
        else {
          return this.$t('task_date_duration_calc.non_work.content_line_start_no_name', [this.moveOrException.targetDate.format('dddd, Do MMMM YYYY')]);
        }
      }
      return '';
    }
    , suggestMoveToDay() {
      if (this.moveOrException != null && this.moveOrException.suggestedDate != null) {
        const taskName = this.taskName || '';
        const suggestDateStr = this.moveOrException.suggestedDate.format('dddd, Do MMMM YYYY');
        if (this.moveOrException.isPrevious) {
          if (taskName) {
            return this.$t('task_date_duration_calc.non_work.choice_move_finish', [taskName, suggestDateStr]);
          }
          else {
            return this.$t('task_date_duration_calc.non_work.choice_move_finish_no_name', [suggestDateStr]);
          }
        }
        if (taskName) {
          return this.$t('task_date_duration_calc.non_work.choice_move_start', [taskName, suggestDateStr]);
        }
        else {
          return this.$t('task_date_duration_calc.non_work.choice_move_start_no_name', [suggestDateStr]);
        }
      }
      return '';
    }
    , suggestCreateException() {
      if (this.moveOrException != null && this.moveOrException.targetDate != null) {
        const targetDateStr = this.moveOrException.targetDate.format('dddd, Do MMMM YYYY');
        return this.$t('task_date_duration_calc.non_work.choice_create_exception', [targetDateStr]);
      }
      return '';
    }
    , moveOrExceptionOptions() {
      return MOVE_OR_EXCEPTION_ACTION_CHOICE;
    }
    , constraintChangeOptions() {
      return CONSTRAINT_CHANGE_ACTION_CHOICE;
    }
    , beyondProjectDateTaskMovedStatements() {
      if (this.taskName != null && this.beyondProjectDate != null && 
          this.beyondProjectDate.targetDate != null && this.beyondProjectDate.projectDate != null) {
        const targetDateStr = this.beyondProjectDate.targetDate.format('dddd, Do MMMM YYYY');
        const projectDateStr = this.beyondProjectDate.projectDate.format('dddd, Do MMMM YYYY');
        const askContinueLine = this.$t("task_date_duration_calc.beyond_project_date.content_line_ask_continue");
        if (this.beyondProjectDate.isStart) {
          let localizedKey = 'task_date_duration_calc.beyond_project_date.content_line_finish1';
          if (this.beyondProjectDate.isStartTrigger) {
            localizedKey = 'task_date_duration_calc.beyond_project_date.content_line_start1';
          }
          const contentLine1 = this.$t(localizedKey, [this.taskName, targetDateStr]);
          const contentLine2 = this.$t('task_date_duration_calc.beyond_project_date.content_line_start2', [projectDateStr]);
          return [
            `${contentLine1} ${contentLine2}`
            , askContinueLine
          ]
        }
        let localizedKey = 'task_date_duration_calc.beyond_project_date.content_line_finish1';
        if (this.beyondProjectDate.isStartTrigger) {
          localizedKey = 'task_date_duration_calc.beyond_project_date.content_line_start1';
        }
        const contentLine1 = this.$t(localizedKey, [this.taskName, targetDateStr]);
        const contentLine2 = this.$t('task_date_duration_calc.beyond_project_date.content_line_finish2', [projectDateStr]);
        return [
          `${contentLine1} ${contentLine2}`
          , askContinueLine
        ]
      }
      return [];
    }
    , constraintChangeStatements() {
      const targetConstraintDate = this.constraintChange.targetConstraintDate != null? ` - ${this.constraintChange.targetConstraintDate.format('dddd, Do MMMM YYYY')}`: '';
      const currentConstraint = `${this.$t('constraint_type.'+this.constraintChange.targetConstraintType)}${targetConstraintDate}`;
      return this.$t('task_date_duration_calc.constraint_change.content_line', [currentConstraint]);
    }
    , suggestChangeConstraint() {
      const suggestedConstraintDate = this.constraintChange.suggestedConstraintDate != null? ` - ${this.constraintChange.suggestedConstraintDate.format('dddd, Do MMMM YYYY')}`: '';
      const suggestedConstraint = `${this.$t('constraint_type.'+this.constraintChange.suggestedConstraintType)}${suggestedConstraintDate}`;
      return this.$t('task_date_duration_calc.constraint_change.choice_change', [suggestedConstraint])
    }
    , suggestKeepConstraint() {
      const currentConstraintDate = this.constraintChange.targetConstraintDate != null? ` - ${this.constraintChange.targetConstraintDate.format('dddd, Do MMMM YYYY')}`: '';
      const currentConstraint = `${this.$t('constraint_type.'+this.constraintChange.targetConstraintType)}${currentConstraintDate}`;
      return this.$t('task_date_duration_calc.constraint_change.choice_keep', [currentConstraint])
    }
    , isNullCalendarProvided() {
      return this.calendar == null;
    }
    , autoMoveForNonWorkingDay() {
      return this.defaultActionForNonWorkPrompt == MOVE_OR_EXCEPTION_ACTION_CHOICE.MOVE;
    }
  }
  , methods: {
    initCalculation() {
      const payload = {
        trigger: this.trigger
        , startDateStr: this.startDateStr
        , startTimeStr: this.startTimeStr
        , closeDateStr: this.closeDateStr
        , closeTimeStr: this.closeTimeStr
        , durationDisplay: this.durationDisplay
        , calendar: this.calendar
        , projScheduleFromStart: this.projectScheduleFromStart
        , taskAutoScheduleMode: this.taskAutoScheduleMode
        , constraintType: this.constraintType
        , constraintDateStr: this.constraintDateStr
        , projectStartDateStr: this.projectStartDateStr
        , projectCloseDateStr: this.projectCloseDateStr
        , oldDateStr: this.oldDateStr
        , oldTimeStr: this.oldTimeStr
        , lockDuration: this.lockDuration
        , autoMoveForNonWorkingDay: this.autoMoveForNonWorkingDay
        , skipOutOfProjectDateCheck: this.skipOutOfProjectDateCheck
        , resizeMode: this.resizeMode
        , disableDurationBuffer: this.disableDurationBuffer
        , durationConversionOpts: this.durationConversionOpts
      }
      
      // console.log('initCalculation', payload); //eslint-disable-line no-console
      this.calculate(payload);
    }
    , calculate(payload, { taskScheduleModeChanged=false } = {}) {
      //                                 |
      //                                 |<-------------------------------+
      //            +------------------->|<---------------------+         |
      //            |                    |                      |         |
      //            |                    V                      |         ^
      //            |               calculate()----------------------> Constraint Change >-+
      //            |          ____ /    |    \_____            |                  V       |
      //            |    _____/          |          \_____      |                  |       |
      //            ^   V                |                V     ^                  |       |
      //     +--<Non Work Day            |             Beyond Project Date Range   |       |
      //     |      V                    V                      V                  |       |
      //     |      |       emit('success', payload)            |                  |       |
      //     |      |       emit('update:show, false)           |                  |       |
      //     |      |                                           |                  |       |
      //     |      +-----> emit('cancel', payload) <-----------+ <----------------+       |
      //     |              emit('update:show, false)                                      V
      //     V                                                                        emit('skip')
      // emit('calendarChange', payload)
      // emit('update:show, false)
      const _payload = cloneDeep(payload);
      let result = null;
      try {
        result = calcDateTimeDurationv2(_payload);
        // console.log('result', cloneDeep(result)); //eslint-disable-line no-console
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        this.abortCalculation();
        return;
      }
      
      if (Object.prototype.hasOwnProperty.call(result, 'modal')) {
        const resultModal = result.modal;
        if (resultModal.type == MODAL_TYPES.NON_WORK_DAY) {
          this.moveOrException.targetDate =  moment.utc(resultModal.dateStr, 'YYYY-MM-DD');
          this.moveOrException.suggestedDate = moment.utc(`${resultModal.suggestedDateStr} ${resultModal.suggestedTimeStr}`, 'YYYY-MM-DD HH:mm');
          this.moveOrException.isPrevious = resultModal.isBackward;
          this.moveOrException.startDateStr = result.startDateStr;
          this.moveOrException.startTimeStr = result.startTimeStr;
          this.moveOrException.closeDateStr = result.closeDateStr;
          this.moveOrException.closeTimeStr = result.closeTimeStr;

          let choice = this.defaultActionForNonWorkPrompt;
          if (this.previousMoveOrExceptionChoice != null) {
            choice = this.previousMoveOrExceptionChoice;
          }
          if (choice == MOVE_OR_EXCEPTION_ACTION_CHOICE.MOVE || 
              choice == MOVE_OR_EXCEPTION_ACTION_CHOICE.CALENDAR_EXCEPTION) {
            this.moveOrException.choice = choice;
            this.moveOrExceptionOk();
          } else {
            this.moveOrExceptionShow = true;
          }
        } else if (resultModal.type == MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE) {
          this.beyondProjectDate.targetDate = moment.utc(resultModal.dateStr, 'YYYY-MM-DD');
          this.beyondProjectDate.projectDate = moment.utc(resultModal.projectDateStr, 'YYYY-MM-DD');
          this.beyondProjectDate.isStart = resultModal.isStart;
          this.beyondProjectDate.isStartTrigger = resultModal.trigger == TRIGGERS.START_DATE || resultModal.trigger == TRIGGERS.START_TIME || (resultModal.trigger == TRIGGERS.DURATION && resultModal.isStart);

          if (this.previousBeyondProjectDateChoice) {
            this.beyondProjectDateOk();
          } else {
            this.beyondProjectDateShow = true;
          }
        } else if (resultModal.type == MODAL_TYPES.CONSTRAINT_CHANGE) {
          this.constraintChange.targetConstraintType = resultModal.constraintType;
          this.constraintChange.targetConstraintDate =  resultModal.constraintDateStr != null? moment.utc(resultModal.constraintDateStr, 'YYYY-MM-DD') : null;
          this.constraintChange.suggestedConstraintType = resultModal.suggestedConstraintType;
          this.constraintChange.suggestedConstraintDate = resultModal.suggestedConstraintDateStr != null? moment.utc(resultModal.suggestedConstraintDateStr, 'YYYY-MM-DD') : null;

          let choice = this.defaultActionForConstraintChangePrompt;
          if (this.previousConstraintChangeChoice != null) {
            choice = this.previousConstraintChangeChoice;
          }
          
          if (choice == CONSTRAINT_CHANGE_ACTION_CHOICE.KEEP || 
              choice == CONSTRAINT_CHANGE_ACTION_CHOICE.CHANGE ||
              choice == CONSTRAINT_CHANGE_ACTION_CHOICE.MANUAL_SCHEDULE) {
            this.constraintChange.choice = choice;
            this.constraintChangeOk();
          } else {
            this.constraintChangeShow = true;
          }
        }
      } else {
        //Constraint Change 'manual_schedule' choice need be passed as part of the payload
        //Same also applied to Manual change Ok choice.
        if (taskScheduleModeChanged === true) {
          result.taskAutoScheduleMode = payload.taskAutoScheduleMode;
        }
        //emit success event with final result payload.
        this.$emit('success', result);
        this.isExpectedClose = true;
        this.$emit('update:show', false);
      }
      
    }
    , skipCalculation() {
      //emit 'skip' event to parent component.
      //Skip the current item and continue to process next item.
      //Useful for parent component to know when to skip the current item and continue to next item.
      this.$emit('skip', {
        trigger: this.trigger
        , oldDateStr: this.oldDateStr
        , oldTimeStr: this.oldTimeStr
        , oldConstraintType: this.oldConstraintType
        , oldConstraintDateStr: this.oldConstraintDateStr
      });
      this.isExpectedClose = true;
      this.$emit('update:show', false);
    }
    , abortCalculation() {
      //emit 'cancel' event with payload to parent component.
      //Useful for parent component to know when to abort action for the current and the remaining items.
      this.$emit('cancel', {
        trigger: this.trigger
        , oldDateStr: this.oldDateStr
        , oldTimeStr: this.oldTimeStr
        , oldConstraintType: this.oldConstraintType
        , oldConstraintDateStr: this.oldConstraintDateStr
      });
      this.isExpectedClose = true;
      this.$emit('update:show', false);
    }
    , constraintChangeOk() {
      // Keep action choice
      if (this.applyAllForConstraintChange) {
        if (this.constraintChange.choice == CONSTRAINT_CHANGE_ACTION_CHOICE.KEEP || 
            this.constraintChange.choice == CONSTRAINT_CHANGE_ACTION_CHOICE.CHANGE ||
            this.constraintChange.choice == CONSTRAINT_CHANGE_ACTION_CHOICE.MANUAL_SCHEDULE) {
          this.previousConstraintChangeChoice = this.constraintChange.choice;
        } else {
          this.previousConstraintChangeChoice = null;
        }
        this.applyAllForConstraintChange = false;
      }
      // A) CONSTRAINT_CHANGE_ACTION_CHOICE == CHANGE
      if (this.constraintChange.choice == CONSTRAINT_CHANGE_ACTION_CHOICE.CHANGE) {

        const payload = {
          //State or flag which affects the calculation flow.
          trigger: this.trigger
          , resizeMode: this.resizeMode
          , calendar: this.calendar
          , lockDuration: this.lockDuration
          , projScheduleFromStart: this.projectScheduleFromStart
          , taskAutoScheduleMode: this.taskAutoScheduleMode
          , projectStartDateStr: this.projectStartDateStr
          , projectCloseDateStr: this.projectCloseDateStr
          , skipOutOfProjectDateCheck: this.localSkipOutOfProjectDateCheck
          , disableDurationBuffer: this.disableDurationBuffer
          // , autoMoveForNonWorkingDay: false
          // , maxDurationInDays: 2500
          , autoConstraintChange: this.autoMoveForNonWorkingDay
          //Values
          , startDateStr: this.startDateStr
          , startTimeStr: this.startTimeStr
          , closeDateStr: this.closeDateStr
          , closeTimeStr: this.closeTimeStr
          , durationDisplay: this.durationDisplay
          , constraintType: this.constraintType
          , constraintDateStr: this.constraintDateStr
          //Previous values will be used in dialog prompt for changes confirmation: e.g.: Inform and ask user whether agree to proceed with the constraint change.
          , prevDateStr: this.oldDateStr
          , prevTimeStr: this.oldTimeStr
        }

        const suggewstedConstraintType = this.constraintChange.suggestedConstraintType;
        const suggestedConstraintDate = this.constraintChange.suggestedConstraintDate;
        payload.constraintType = suggewstedConstraintType;
        payload.constraintDateStr = suggestedConstraintDate != null? suggestedConstraintDate.format('YYYY-MM-DD') : null;
        this.calculate(payload);

        //TODO: remember to update non-work and out of project range modal payload.
      } 
      // B) CONSTRAINT_CHANGE_ACTION_CHOICE == MANUAL_SCHEDULE
      else if (this.constraintChange.choice == CONSTRAINT_CHANGE_ACTION_CHOICE.MANUAL_SCHEDULE) {
        const payload = {
          //State or flag which affects the calculation flow.
          trigger: this.trigger
          , resizeMode: this.resizeMode
          , calendar: this.calendar
          , lockDuration: this.lockDuration
          , projScheduleFromStart: this.projectScheduleFromStart
          , taskAutoScheduleMode: false
          , projectStartDateStr: this.projectStartDateStr
          , projectCloseDateStr: this.projectCloseDateStr
          , skipOutOfProjectDateCheck: this.localSkipOutOfProjectDateCheck
          , autoMoveForNonWorkingDay: this.autoMoveForNonWorkingDay
          , disableDurationBuffer: this.disableDurationBuffer
          , durationConversionOpts: this.durationConversionOpts
          // , maxDurationInDays: 2500
          // , autoConstraintChange: false
          //Values
          , startDateStr: this.startDateStr
          , startTimeStr: this.startTimeStr
          , closeDateStr: this.closeDateStr
          , closeTimeStr: this.closeTimeStr
          , durationDisplay: this.durationDisplay
          , constraintType: this.constraintType
          , constraintDateStr: this.constraintDateStr
          //Previous values will be used in dialog prompt for changes confirmation: e.g.: Inform and ask user whether agree to proceed with the constraint change.
          , prevDateStr: this.oldDateStr
          , prevTimeStr: this.oldTimeStr
        }
        this.calculate(payload, { taskScheduleModeChanged: true });
      } 
      // C) CONSTRAINT_CHANGE_ACTION_CHOICE == KEEP
      else {
        this.skipCalculation();
      }
    }
    , moveOrExceptionOk() {
      // Keep action choice
      if (this.applyAllForMoveOrException) {
        if (this.moveOrException.choice == MOVE_OR_EXCEPTION_ACTION_CHOICE.MOVE || 
            this.moveOrException.choice == MOVE_OR_EXCEPTION_ACTION_CHOICE.CALENDAR_EXCEPTION) {
          this.previousMoveOrExceptionChoice = this.moveOrException.choice;
        } else {
          this.previousMoveOrExceptionChoice = null;
        }
        this.applyAllForMoveOrException = false;
      }
      // A) MOVE_OR_EXCEPTION_ACTION_CHOICE == MOVE
      if (this.moveOrException.choice == MOVE_OR_EXCEPTION_ACTION_CHOICE.MOVE) {
        let constraintDateStr = this.constraintDateStr;
        //Override constraintDate with suggested date when trigger is related to constraint.
        //Explanation: The non-work modal is triggered by constraintDate which fall on non-work day when user updates either constraintType or constraintDate. 
        //             And 'move' action is chosen by user when non-work modal shown.
        if (this.trigger == TRIGGERS.CONSTRAINT_TYPE || this.trigger == TRIGGERS.CONSTRAINT_DATE) {
          if (isConstraintTypeStartDateRelated(this.constraintType) || isConstraintTypeCloseDateRelated(this.constraintType)) {
            constraintDateStr = this.moveOrException.suggestedDate.format('YYYY-MM-DD');
          }
        }

        let _startDateStr = this.moveOrException.startDateStr? this.moveOrException.startDateStr : this.startDateStr;
        let _startTimeStr = this.moveOrException.startTimeStr? this.moveOrException.startTimeStr : this.startTimeStr;
        let _closeDateStr = this.moveOrException.closeDateStr? this.moveOrException.closeDateStr : this.closeDateStr;
        let _closeTimeStr = this.moveOrException.closeTimeStr? this.moveOrException.closeTimeStr : this.closeTimeStr;
        let _trigger = this.trigger;
        const suggestedDate = this.moveOrException.suggestedDate;
        if (this.moveOrException.isPrevious) {
          _trigger = TRIGGERS.CLOSE_DATE;
          _closeDateStr = suggestedDate.format('YYYY-MM-DD');
          _closeTimeStr = suggestedDate.format('HH:mm');
        } else {
          _trigger = TRIGGERS.START_DATE;
          _startDateStr = suggestedDate.format('YYYY-MM-DD');
          _startTimeStr = suggestedDate.format('HH:mm');
        }

        const payload = {
          //State or flag which affects the calculation flow.
          trigger: _trigger //this.trigger
          , resizeMode: this.resizeMode
          , calendar: this.calendar
          , lockDuration: this.lockDuration
          , projScheduleFromStart: this.projectScheduleFromStart
          , taskAutoScheduleMode: this.taskAutoScheduleMode
          , projectStartDateStr: this.projectStartDateStr
          , projectCloseDateStr: this.projectCloseDateStr
          , skipOutOfProjectDateCheck: this.localSkipOutOfProjectDateCheck
          , autoMoveForNonWorkingDay: true
          , disableDurationBuffer: this.disableDurationBuffer
          , durationConversionOpts: this.durationConversionOpts
          // , maxDurationInDays: 2500
          // , autoConstraintChange: false
          //Values
          , startDateStr: _startDateStr
          , startTimeStr: _startTimeStr
          , closeDateStr: _closeDateStr
          , closeTimeStr: _closeTimeStr
          , durationDisplay: this.durationDisplay
          , constraintType: this.constraintType
          , constraintDateStr
          //Previous values will be used in dialog prompt for changes confirmation: e.g.: Inform and ask user whether agree to proceed with the date change when new date is non-work day.
          , prevDateStr: this.oldDateStr
          , prevTimeStr: this.oldTimeStr
        }
        // console.log('payload', payload) //eslint-disable-line no-console
        
        this.calculate(payload);
      } 
      // B) MOVE_OR_EXCEPTION_ACTION_CHOICE == CALENDAR_EXCEPTION
      else if (this.moveOrException.choice == MOVE_OR_EXCEPTION_ACTION_CHOICE.CALENDAR_EXCEPTION) {
        //1. Construct to-add or to-update exception list
        //2. Emit event 'calendarChange' with payload { toAddExceptions, toUpdateExceptions }
        
        const targetDate = this.moveOrException.targetDate;
        const targetDateStr = targetDate.format('YYYY-MM-DD');
        const leaveList = this.calendar.Leave || [];
        const index = leaveList.findIndex(i => i.startDate <= targetDateStr && targetDateStr <= i.endDate);
        const toAddExceptions = [];
        const toUpdateExceptions = [];

        const cal = this.calendar;
        const avaiableWorkingDay = nextAvailableWorkingDay(cal, targetDate.clone(), { isBackward: true });
        const workStartHour = getEarliestOrLatestWorkHour(cal, avaiableWorkingDay, { isLatest: false });
        const workEndHour =  getEarliestOrLatestWorkHour(cal, avaiableWorkingDay, { isLatest: true });
        const baseDateTime = moment.utc().hour(0).minute(0).second(0).millisecond(0);
        const workStartHourInMs = moment.utc(workStartHour, 'HH:mm').diff(baseDateTime);
        const workEndHourInMs = moment.utc(workEndHour, 'HH:mm').diff(baseDateTime);
        
        if (index > -1) {
          const leaveObj = cloneDeep(leaveList[index]);
          const isExactMatch = targetDateStr == leaveObj.startDate && targetDateStr == leaveObj.endDate;

          //Replace the exception if an exact-match exception exists.
          //Otherwise, split the exception: update (shorten) the existing exception, and add new exception.
          if (isExactMatch) {
            //replace 
            leaveObj.name = WORK_EXCEPTION_NAME;
            leaveObj.isWorking = true;
            leaveObj.type = CALENDAR_EXCEPTION_TYPES.WORKING;
            leaveObj.startHour = workStartHourInMs;
            leaveObj.endHour = workEndHourInMs;
            if (this.calendar.Working == null || this.calendar.Working == undefined) {
              this.calendar.Working = [];
            }
            this.calendar.Working.push(leaveObj);
            leaveList.splice(index, 1);
            toUpdateExceptions.push(leaveObj);
          } else {
            //split
            const leaveStartDate = moment.utc(leaveObj.startDate, 'YYYY-MM-DD');
            const leaveCloseDate = moment.utc(leaveObj.endDate, 'YYYY-MM-DD');
            
            const leaveStartDateStr = leaveObj.startDate;
            const leaveCloseDateStr = leaveObj.endDate;

            const isTargetDateOnStartDate = leaveStartDateStr == targetDateStr;
            const isTargetDateOnEndDate = leaveCloseDateStr == targetDateStr;
            
            //Working exception for targetDate
            toAddExceptions.push({
              name: WORK_EXCEPTION_NAME
              , startDate: targetDateStr
              , endDate: targetDateStr
              , startHour: workStartHourInMs
              , endHour: workEndHourInMs
              , isWorking: true
              , type: CALENDAR_EXCEPTION_TYPES.WORKING
            })
            
            if (isTargetDateOnStartDate) {
              leaveObj.startDate = leaveStartDate.clone().add(1, 'day').format('YYYY-MM-DD');
              toUpdateExceptions.push(leaveObj);
              
            } else if (isTargetDateOnEndDate) { 
              leaveObj.endDate = leaveCloseDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
              toUpdateExceptions.push(leaveObj);
            } else { // in middle of the date range
              //Second Part
              const secondPart = cloneDeep(leaveObj);
              delete secondPart.uuId;
              secondPart.startDate = targetDate.clone().add(1, 'day').format('YYYY-MM-DD');
              toAddExceptions.push(secondPart);
              
              //First Part
              leaveObj.endDate = targetDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
              toUpdateExceptions.push(leaveObj);
            }
          }
          this.$emit('calendarChange', { toAddExceptions, toUpdateExceptions, skipOutOfProjectDateCheck: this.localSkipOutOfProjectDateCheck });
          this.isExpectedClose = true;
          this.$emit('update:show', false);
        } else {
          //1. Construct to-add or to-update exception list
          //2. Emit event 'calendarChange' with payload { toAddExceptions, toUpdateExceptions }
          const toAddExceptions = [{
            name: WORK_EXCEPTION_NAME
            , startDate: targetDateStr
            , endDate: targetDateStr
            , startHour: workStartHourInMs
            , endHour: workEndHourInMs
            , isWorking: true
            , type: CALENDAR_EXCEPTION_TYPES.WORKING
          }]
          this.$emit('calendarChange', { toAddExceptions, toUpdateExceptions: [], skipOutOfProjectDateCheck: this.localSkipOutOfProjectDateCheck });
          this.isExpectedClose = true;
          this.$emit('update:show', false);
        }
      }
    }
    , beyondProjectDateOk() {
      // Keep action choice
      if (this.applyAllForBeyondProjectDate) {
        this.previousBeyondProjectDateChoice = true;
        this.applyAllForBeyondProjectDate = false;
      } else {
        this.previousBeyondProjectDateChoice = false;
      }
      this.localSkipOutOfProjectDateCheck = true; //Set to true to supress subsequence check in the same session.

      const payload = {
        //State or flag which affects the calculation flow.
        trigger: this.trigger
        , resizeMode: this.resizeMode
        , calendar: this.calendar
        , lockDuration: this.lockDuration
        , projScheduleFromStart: this.projectScheduleFromStart
        , taskAutoScheduleMode: this.taskAutoScheduleMode
        , projectStartDateStr: this.projectStartDateStr
        , projectCloseDateStr: this.projectCloseDateStr
        , skipOutOfProjectDateCheck: true
        , autoMoveForNonWorkingDay: this.autoMoveForNonWorkingDay
        , disableDurationBuffer: this.disableDurationBuffer
        , durationConversionOpts: this.durationConversionOpts
        // , maxDurationInDays: 2500
        // , autoConstraintChange: false
        //Values
        , startDateStr: this.startDateStr
        , startTimeStr: this.startTimeStr
        , closeDateStr: this.closeDateStr
        , closeTimeStr: this.closeTimeStr
        , durationDisplay: this.durationDisplay
        , constraintType: this.constraintType
        , constraintDateStr: this.constraintDateStr
        //Previous values will be used in dialog prompt for changes confirmation: e.g.: Inform and ask user whether agree to proceed with the constraint change.
        , prevDateStr: this.oldDateStr
        , prevTimeStr: this.oldTimeStr
      }

      this.calculate(payload);
    }
    , handleHide() {
      //Call abotCalculation() when the modal is closed due to esc key pressing.
      if (!this.isExpectedClose) {
        this.abortCalculation();
      }
    }
    , manualScheduleChangeOk() {
       // Keep action choice
       if (this.applyAllForManualChange) {
        this.previousManualChangeChoice = true;
        this.applyAllForManualChange = false;
      } else {
        this.previousManualChangeChoice = false;
      }

      const payload = {
        trigger: this.trigger
        , startDateStr: this.startDateStr
        , startTimeStr: this.startTimeStr
        , closeDateStr: this.closeDateStr
        , closeTimeStr: this.closeTimeStr
        , durationDisplay: this.durationDisplay
        , calendar: this.calendar
        , projScheduleFromStart: this.projectScheduleFromStart
        , taskAutoScheduleMode: false //Override and set to false
        , constraintType: this.constraintType
        , constraintDateStr: this.constraintDateStr
        , projectStartDateStr: this.projectStartDateStr
        , projectCloseDateStr: this.projectCloseDateStr
        , oldDateStr: this.oldDateStr
        , oldTimeStr: this.oldTimeStr
        , lockDuration: this.lockDuration
        , autoMoveForNonWorkingDay: this.autoMoveForNonWorkingDay
        , skipOutOfProjectDateCheck: this.skipOutOfProjectDateCheck
        , resizeMode: this.resizeMode
        , disableDurationBuffer: this.disableDurationBuffer
        , durationConversionOpts: this.durationConversionOpts
      }
      // console.log('manualScheduleChangeOk payload', payload)
      this.calculate(payload, { taskScheduleModeChanged: true });
    }

    
  }
}
</script>

<style lang="scss">
.moveOrException-modal, .beyondProjectDate-modal, .constraintChange-modal, .manual-schedule-modal {
 .apply-to-all {
    position: absolute;
    left: 15px;
  }
}
</style>